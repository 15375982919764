





































































import { Component } from 'vue-property-decorator';
import { GroupEntityType } from '@common-src/model/enum';
import DeviceTypeService from '@common-src/service/device-type';
import DeviceService from '@common-src/service/device';
import TableComponent from '@common-src/mixins/table-component';
import { DeviceEntityModel, DeviceQueryModel } from '@common-src/entity-model/device-entity';

@Component
export default class DeviceLinkChildDialog extends TableComponent<DeviceEntityModel, DeviceQueryModel> {
    allTypeList: Array<any> = null;
    queryLoading: boolean = false;
    dialogVisible: boolean = false;
    GroupEntityType = GroupEntityType;
    parentId: string;
    rightDataList: Array<DeviceEntityModel> = [];

    created() {
        this.pageSize = null;
        this.isLoadMore = true;
        this.isAutoQuery = false;
        this.initTable({ service: DeviceService, queryModel: new DeviceQueryModel() });
    }

    dialogOpen(parentId: string, deviceTypeId: string, driverIdentifier?: string) {
        this.parentId = parentId;
        this.dialogVisible = true;
        this.rightDataList = [];

        // const deviceChildQueryModel = new DeviceQueryModel();
        // deviceChildQueryModel.parentId = parentId;
        this.queryLoading = true;
        // Promise.all([DeviceTypeService.getDeviceTypeForLinkDevice(parentId), DeviceService.query(deviceChildQueryModel, 1, 999)]).then(res => {
        DeviceTypeService.getDeviceTypeForLinkDevice(deviceTypeId).then(res => {
            this.allTypeList = _.map(res, item => ({ id: item.id, name: item.name, type: item.type }));
            this.queryModel.supportedTypeIdList = _.map(res, item => item.id);
            if (!this.queryModel.supportedTypeIdList || this.queryModel.supportedTypeIdList.length === 0) {
                this.showMessageWarning('关联产品类型为空，不能关联子设备。请前往产品添加关联产品类型');
                this.queryLoading = false;
                return;
            }
            this.queryModel.driverIdentifier = driverIdentifier;
            this.getList().finally(() => {
                this.queryLoading = false;
            });
            // this.rightDataList = _.get(res, '[1].items');
            // this.getList().then(() => {
            //     _.forEach(this.rightDataList, child => {
            //         const device = _.find(this.listData, item => item.id === child.id);
            //         if (device) {
            //             device.check = true;
            //         }
            //     });
            // });
        }).catch(err => {
            this.queryLoading = false;
            this.showContactAdmin();
            this.dialogClose();
        });
    }

    searchClickHandler() {
        this.page = 1;
        this.getList();
    }

    dialogOK() {
        return DeviceService.links(this.parentId, _.map(this.rightDataList, item => item.id)).then((ret) => {
            this.dialogClose();
            this.$emit('dialogOK', ret);
        }).catch(err => {
            throw err;
        });
    }

    dialogClose(): void {
        this.queryModel = new DeviceQueryModel();
        this.listData = [];
        this.rightDataList = [];
        this.allTypeList = null;
        this.parentId = null;
        this.dialogVisible = false;
        this.page = 1;
        this.total = 0;
    }

    checkOnChange(model: DeviceEntityModel) {
        if (model.check) {
            this.rightDataList.push(model);
        } else {
            const index = _.findIndex(this.rightDataList, item => item.id === model.id);
            if (index > -1) {
                this.rightDataList.splice(index, 1);
            }
        }
    }

    get AllTypeList() {
        return this.getAllOptions(_.map(this.allTypeList, item => {
            return {
                name: item.name,
                value: item.id
            };
        }));
    }
    get AllCheck() {
        if (!this.listData || this.listData.length === 0) {
            return false;
        }
        return _.filter(this.listData, item => item.check).length === this.listData.length;
    }
    set AllCheck(val: boolean) {
        _.forEach(this.listData, item => {
            item.check = val;
            if (val) {
                const index = _.findIndex(this.rightDataList, rightItem => rightItem.id === item.id);
                if (index === -1) {
                    this.rightDataList.push(item);
                }
            }
        });
        if (!val) {
            this.rightDataList = [];
        }
    }
    removeItemClick(model, index) {
        model.check = false;
        const listDataItem = _.find(this.listData, item => item.id === model.id);
        if (listDataItem) {
            listDataItem.check = false;
        }
        this.rightDataList.splice(index, 1);
    }
    removeAllClick() {
        this.rightDataList = [];
        _.forEach(this.listData, item => {
            item.check = false;
        });
    }
}
