






































































































































import { Component, Mixins } from 'vue-property-decorator';
import GroupComponent from '@common-src/mixins/group-component';
import TableTreeComponent from '@common-src/mixins/table-tree-component';
import TableDialogFormComponent from '@common-src/mixins/table-dialog-form-component';
import { ImportExcelMatchEntityModel, DeviceEntityModel, DeviceQueryModel, DeviceImportEntityModel, DeviceImportExcelEntityModel, DeviceCopyEntityModel, ExportTemplateEntityModel } from '@common-src/entity-model/device-entity';
import DeviceService from '@common-src/service3/device';
import DeviceService2 from '@common-src/service/device';
import DeviceDetailDrawer from './device-detail-drawer.vue';
import DeviceLinkChildDialog from './device-link-child-dialog.vue';
import { DeviceTypeEnum, ViewModeType, GroupEntityType, FileExportType } from '@common-src/model/enum';
import FormDialogComponent from '@common-src/mixins/form-dialog-component';
import DeviceBatchGroupDialog from './device-batch-group.vue';
import DeviceBatchSystemDialog from './device-batch-system.vue';
import DeviceBatchPositionDialog from './device-batch-position.vue';
import DownloadExcelDialog from './download-excel-dialog.vue';
import ImportExcelBatchDialog from './import-excel-batch-dialog.vue';
import ImportExcelDialog from './import-excel-dialog.vue';
import ImportExcelMatchDialog from './import-excel-match-dialog.vue';
import { ModuleType } from '@common-src/enum/module-type.enum';
import RpcComponent from '@common-src/mixins/rpc-component';

@Component({
    components: {
        'device-detail-drawer': DeviceDetailDrawer,
        'device-link-child-dialog': DeviceLinkChildDialog,
        'device-batch-group-dialog': DeviceBatchGroupDialog,
        'device-batch-system-dialog': DeviceBatchSystemDialog,
        'device-batch-position-dialog': DeviceBatchPositionDialog,
        'download-excel-dialog': DownloadExcelDialog,
        'import-excel-dialog': ImportExcelDialog,
        'import-excel-batch-dialog': ImportExcelBatchDialog,
        'import-excel-match-dialog': ImportExcelMatchDialog
    }
})
export default class DeviceListComponent extends Mixins(TableDialogFormComponent, GroupComponent, TableTreeComponent, RpcComponent) {
    DeviceEntityModel = DeviceEntityModel;
    DeviceTypeEnum = DeviceTypeEnum;
    deviceTypeList: Array<any> = null;

    selectGroupIds: Array<any>;
    selectSpaceIds: Array<any>;

    get IsEdge() {
        return JTL.moduleType === ModuleType.EDGE;
    }

    created() {
        if (!this.IsEdge) { // 非边缘网关建立websocket连接
            this.clearBatchNoStorage();
            this.connectWebsocket();
        }

        this.isAutoQuery = false;
        const deviceQueryModel = new DeviceQueryModel();
        const deviceTypeId = this.$route.query.deviceTypeId as string;
        const deviceName = this.$route.query.name as string;
        if (deviceTypeId) {
            deviceQueryModel.typeId = deviceTypeId;
        } else {
            deviceQueryModel.typeId = JTL.CONSTANT.ALL_OPTION.value;
        }

        if (deviceName) {
            deviceQueryModel.name = deviceName;
        }

        const deviceService = new DeviceService();
        let columns = null;
        try {
            const localTableColumns = window.localStorage.getItem('device_table_column');
            if (localTableColumns) {
                const localColumnsList = JSON.parse(localTableColumns);
                const localColumnsTitleList = _.map(_.filter(localColumnsList, item => item.visible), item => item.title);
                columns = _.map(DeviceEntityModel.getTableColumns(), (item: any) => {
                    item.visible = localColumnsTitleList.indexOf(item.title) > -1;
                    return item;
                });
            } else {
                columns = DeviceEntityModel.getTableColumns();
            }
        } catch (e) {
            console.warn(e);
            columns = DeviceEntityModel.getTableColumns();
        }
        this.initTable({ service: deviceService, queryModel: deviceQueryModel, tableColumns: columns });
        this.treeEntityService = deviceService as any;
    }

    beforeDestroy() {
        this.$message.destroy();
    }

    get SpaceId() {
        return this.$route.query.spaceId as string;
    }

    // 百分比规范调整
    getValue(item) {
        if (_.get(item, 'unit') === '%') {
            return `${parseFloat((_.toNumber(item.value) * 100).toPrecision(12))}%`;
        } else return `${item.value}${item.unit || ''}`;
    }

    groupAndSpaceChange(currentTabKey: string) {
        if (currentTabKey === 'group' && this.selectSpaceIds) {
            (this.queryModel as DeviceQueryModel).groupIds = this.selectGroupIds;
            (this.queryModel as DeviceQueryModel).spaceIds = null;
        } else if (this.selectSpaceIds) {
            (this.queryModel as DeviceQueryModel).groupIds = null;
            (this.queryModel as DeviceQueryModel).spaceIds = this.selectSpaceIds;
        }
    }

    treeGroupSelect(groupIds: Array<string>) {
        this.selectGroupIds = groupIds;
        (this.queryModel as DeviceQueryModel).groupIds = groupIds;
        const groupId = _.first(groupIds);
        this.$router.push({
            path: this.$route.path,
            query: {
                groupId,
                spaceId: this.SpaceId
            }
        });
        this.getList();
    }
    treeSpaceSelect(spaceIds: Array<string>) {
        const spaceId = _.first(spaceIds);
        this.selectSpaceIds = spaceIds;
        (this.queryModel as DeviceQueryModel).spaceIds = spaceIds;
        this.$router.push({
            path: '/dashboard/device-list',
            query: {
                groupId: this.GroupId,
                spaceId
            }
        });
        this.getList();
    }

    // beforeRouteEnter(to, from, next) {
    //     CommonService.getDeviceTypeOptions().then(res => {
    //         next(vm => vm.initData(res));
    //     }).catch(error => {
    //         next();
    //     });
    // }

    // initData(data) {
    //     this.deviceTypeList = data;
    // }
    batchEditSys() {
        if (!this.selectedRowKeys || this.selectedRowKeys.length === 0) {
            this.showMessageWarning('请先选择设备');
            return;
        }
        (this.$refs.DeviceBatchSystemDialog as DeviceBatchSystemDialog).dialogOpen(this.getSelectedRowIds(), null, ViewModeType.NEW);
    }
    editDeviceClick(model: DeviceEntityModel) {
        this.editClick(model);
    }

    detailClick(model: DeviceEntityModel) {
        (this.$refs.deviceDetailDrawer as DeviceDetailDrawer).drawerShow(model);
    }
    copyClick(model: DeviceEntityModel) {
        const copyModel = new DeviceCopyEntityModel();
        copyModel.baseName = model.displayName;
        copyModel.parentId = model.id;
        (this.$refs.formDialog as FormDialogComponent<any>).dialogOpen(copyModel, DeviceService2, ViewModeType.NEW);
        (this.$refs.formDialog as FormDialogComponent<any>).dialogTitle = '批量复制设备';
    }
    importClick(model: DeviceEntityModel) {
        const importModel = new DeviceImportEntityModel();
        importModel.baseName = model.displayName;
        importModel.groupId = model.groupId;
        importModel.groupType = model.groupType;
        importModel.positionId = model.positionId;
        importModel.typeId = model.typeId;
        importModel.parentId = model.id;
        (this.$refs.formDialog as FormDialogComponent<any>).dialogOpen(importModel, DeviceService2, ViewModeType.NEW);
        (this.$refs.formDialog as FormDialogComponent<any>).dialogTitle = '批量添加设备';
    }

    deleteBatchClick() {
        if (!this.selectedRowKeys || this.selectedRowKeys.length === 0) {
            this.showMessageWarning('请先选择设备');
            return;
        }
        const confirm = this.$confirm({
            title: '批量删除',
            content: `确认删除${this.selectedRowKeys?.length}个设备`,
            onOk: () => {
                this.startFullScreenLoading('正在删除');
                return DeviceService.batchDelete(this.getSelectedRowIds()).then((ret) => {
                    this.showMessageSuccess('批量删除成功');
                    this.getList();
                    return true;
                }).catch((err) => {
                    throw err;
                }).finally(() => {
                    this.stopFullScreenLoading();
                });
            },
            onCancel: () => {
                confirm.destroy();
            }
        });
    }

    groupBatchClick() {
        if (!this.selectedRowKeys || this.selectedRowKeys.length === 0) {
            this.showMessageWarning('请先选择设备');
            return;
        }
        (this.$refs.deviceBatchGroupDialog as DeviceBatchGroupDialog).dialogOpen(this.getSelectedRowIds(), null, ViewModeType.NEW);
    }
    positionBatchClick() {
        if (!this.selectedRowKeys || this.selectedRowKeys.length === 0) {
            this.showMessageWarning('请先选择设备');
            return;
        }
        (this.$refs.deviceBatchPositionDialog as DeviceBatchPositionDialog).dialogOpen(this.getSelectedRowIds(), null, ViewModeType.NEW);
    }

    unlinkBatchClick() {
        if (!this.selectedRowKeys || this.selectedRowKeys.length === 0) {
            this.showMessageWarning('请先选择设备');
            return;
        }
        const confirm = this.$confirm({
            title: '批量解除关联',
            content: `确认批量解除关联`,
            onOk: () => {
                this.startFullScreenLoading('批量解除关联');
                return DeviceService2.batchUnlink(this.getSelectedRowIds()).then((ret) => {
                    this.showMessageSuccess('批量解除关联成功');
                    this.getList();
                    return true;
                }).catch((err) => {
                    throw err;
                }).finally(() => {
                    this.stopFullScreenLoading();
                });
            },
            onCancel: () => {
                confirm.destroy();
            }
        });
    }

    downloadExcelClick() {
        const exportModel = new ExportTemplateEntityModel();
        (this.$refs.downloadExcelDialog as DownloadExcelDialog).dialogOpen(exportModel, null, ViewModeType.NEW);
    }
    batchExportSys() {
        if (!this.selectedRowKeys || this.selectedRowKeys.length === 0) {
            this.showMessageWarning('请先选择设备');
            return;
        }
        const exportModel = new ExportTemplateEntityModel();
        exportModel.dialogType = FileExportType.EXPORT;
        this.startFullScreenLoading('正在导出...');
        // exportModel.selectedDeviceIds = this.getSelectedRowIds();
        DeviceService2.exportBatchExcelResult(this.getSelectedRowIds()).then(res => {
            if (res && res.er !== -1) {
                this.showMessageError(res.erMessage);
            } else {
                this.showMessageSuccess('导出模板成功');
                this.refreshClick();
            }
        }).catch(err => {
            throw err;
        }).finally(() => {
            this.stopFullScreenLoading();
        });
        // (this.$refs.downloadExcelDialog as DownloadExcelDialog).dialogOpen(exportModel, null, ViewModeType.NEW);
    }
    importExcelClick(model: DeviceEntityModel) {
        const importModel = new DeviceImportExcelEntityModel();
        if (model) {
            importModel.groupId = model.groupId;
            importModel.groupType = model.groupType || GroupEntityType.DEVICE;
            // importModel.positionId = model.positionId;
            importModel.parentId = model.id;
        }
        (this.$refs.importExcelDialog as ImportExcelDialog).dialogOpen(importModel, DeviceService2, ViewModeType.NEW);
    }
    importProtocolExcelClick(model: DeviceEntityModel) {
        const importModel = new DeviceImportExcelEntityModel();
        if (model) {
            importModel.groupId = model.groupId;
            importModel.groupType = model.groupType || GroupEntityType.DEVICE;
            importModel.parentId = model.id;
            importModel.isProtocol = true;
        }
        (this.$refs.importExcelDialog as ImportExcelDialog).dialogOpen(importModel, DeviceService2, ViewModeType.NEW);
    }
    unlinkDeviceChildClick(model: DeviceEntityModel) {
        this.unlinkChildClick(model).then(res => {
            // this.listData = _.union(this.listData, [model]);
            // this.$forceUpdate();
            this.showMessageSuccess('解除关联成功');
            this.getList();
        });
    }
    importExcelMatchClick() {
        const importModel = new ImportExcelMatchEntityModel();
        (this.$refs.importExcelMatchDialog as ImportExcelMatchDialog).dialogOpen(importModel, null, ViewModeType.NEW);
    }

    importExcelBatchClick(batchId: string) {
        (this.$refs.importExcelBatchDialog as ImportExcelBatchDialog).dialogOpen(batchId);
    }

    deviceQueryChange(formControl) {
        if (formControl?.key === 'name') {
            const qm = (this.queryModel as DeviceQueryModel);
            if (qm.name) {
                qm.forceRoot = false;
            }
        }
    }
}
