var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        title: _vm.drawerTitle,
        placement: _vm.placement,
        width: "80vw",
        closable: _vm.closable,
        visible: _vm.drawerVisible,
        "destroy-on-close": true,
      },
      on: { close: _vm.drawerClose },
    },
    [
      _c(
        "div",
        { staticClass: "device-detail-component table-component" },
        [
          _c(
            "a-tabs",
            {
              attrs: { type: "card" },
              model: {
                value: _vm.tabIndex,
                callback: function ($$v) {
                  _vm.tabIndex = $$v
                },
                expression: "tabIndex",
              },
            },
            [
              _c("a-tab-pane", { key: "1", attrs: { tab: "设备信息" } }, [
                _c(
                  "div",
                  { staticClass: "deviceInfo" },
                  [
                    _c(
                      "a-descriptions",
                      { staticClass: "tab-margin-16", attrs: { bordered: "" } },
                      [
                        _c(
                          "div",
                          { attrs: { slot: "title" }, slot: "title" },
                          [
                            _c("span", [_vm._v("基本信息")]),
                            _c(
                              "jtl-button",
                              {
                                staticClass: "description-title-button m-l-12",
                                attrs: { type: "primary" },
                                on: { click: _vm.editClick },
                              },
                              [_vm._v("编辑")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "设备编号" } },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "mediaWidth",
                                attrs: { title: _vm.deviceModel.code },
                              },
                              [_vm._v(_vm._s(_vm.deviceModel.code))]
                            ),
                          ]
                        ),
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "设备令牌" } },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "mediaWidth",
                                attrs: { title: _vm.deviceModel.accessToken },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.deviceModel.accessToken) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "span",
                              {
                                staticClass: "jtl-link",
                                on: { click: _vm.copyCodeClick },
                              },
                              [_vm._v("复制")]
                            ),
                          ]
                        ),
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "创建时间" } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("dateFormat")(
                                    _vm.deviceModel.createdTime
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "设备名称" } },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "mediaWidth",
                                attrs: { title: _vm.deviceModel.displayName },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.deviceModel.displayName) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "安装位置" } },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.deviceModel.positionName) + " "
                            ),
                          ]
                        ),
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "最后上线时间" } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("dateFormat")(
                                    _vm.deviceModel.lastActivityTime
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "当前状态" } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("deviceStatusFilter")(
                                    _vm.deviceModel.Online
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "故障状态" } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.deviceModel.faultStatusDesc) +
                                " "
                            ),
                          ]
                        ),
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "所属产品" } },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "mediaWidth",
                                attrs: { title: _vm.deviceModel.typeName },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.deviceModel.typeName) + " "
                                ),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "驱动" } },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.deviceModel.driverName) + " "
                            ),
                          ]
                        ),
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "设备ID" } },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "mediaWidth",
                                attrs: { title: _vm.deviceId },
                              },
                              [_vm._v(" " + _vm._s(_vm.deviceId) + " ")]
                            ),
                            _c(
                              "span",
                              {
                                staticClass: "jtl-link",
                                on: { click: _vm.copyDeviceIdClick },
                              },
                              [_vm._v("复制")]
                            ),
                          ]
                        ),
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "所属网关" } },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "mediaWidth",
                                attrs: {
                                  title: _vm._f("text")(
                                    _vm.deviceModel.parentPath
                                  ),
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("text")(_vm.deviceModel.parentPath)
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "设备分组" } },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.deviceModel.groupName) + " "
                            ),
                          ]
                        ),
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "备注名称" } },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "mediaWidth",
                                attrs: { title: _vm.deviceModel.remark },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.deviceModel.remark) + " "
                                ),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "服务范围" } },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "mediaWidth",
                                attrs: { title: _vm.deviceModel.serviceArea },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.deviceModel.serviceArea) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c("br"),
                  ],
                  1
                ),
              ]),
              _c(
                "a-tab-pane",
                { key: "5", attrs: { tab: "物模型定义" } },
                [
                  _c("FunctionIndexComponent", {
                    attrs: { "device-type-model": _vm.deviceAttrModel },
                  }),
                ],
                1
              ),
              _c(
                "a-tab-pane",
                { key: "2", attrs: { tab: "物模型数据" } },
                [
                  _vm.deviceId
                    ? _c("device-things-index-component", {
                        attrs: {
                          "event-list": _vm.deviceAttrModel,
                          "device-id": _vm.deviceId,
                          "is-online": _vm.isOnline,
                          "entity-type": _vm.AttributeEntityType.DEVICE,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "a-tab-pane",
                { key: "3", attrs: { tab: "设备控制" } },
                [
                  _vm.deviceId
                    ? _c("device-method-component", {
                        attrs: { "device-id": _vm.deviceId },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm.driverModel.type !== _vm.deviceTypeEnum.INNER_GATEWAY
                ? _c("a-tab-pane", { key: "4", attrs: { tab: "连接配置" } }, [
                    _c(
                      "div",
                      { staticClass: "deviceConfiguration" },
                      [
                        _vm.IsShowDeviceAttr && _vm.serverAttrsData.length > 0
                          ? _c(
                              "a-descriptions",
                              {
                                staticClass: "tab-margin-16",
                                attrs: { bordered: "" },
                              },
                              [
                                _c(
                                  "div",
                                  { attrs: { slot: "title" }, slot: "title" },
                                  [
                                    _c("span", [_vm._v("服务端属性")]),
                                    !_vm.IsdeviceAttrEdit
                                      ? _c(
                                          "jtl-button",
                                          {
                                            staticClass:
                                              "description-title-button m-l-12",
                                            attrs: { type: "primary" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.deviceAttrClick(true)
                                              },
                                            },
                                          },
                                          [_vm._v("编辑")]
                                        )
                                      : _c(
                                          "span",
                                          { staticClass: "m-l-12" },
                                          [
                                            _c(
                                              "jtl-button",
                                              {
                                                staticClass:
                                                  "description-title-button",
                                                attrs: { type: "primary" },
                                                on: {
                                                  click: _vm.saveDeviceAttr,
                                                },
                                              },
                                              [_vm._v("保存")]
                                            ),
                                            _c(
                                              "jtl-button",
                                              {
                                                staticClass:
                                                  "description-title-button m-l-12",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.deviceAttrClick(
                                                      false
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("取消")]
                                            ),
                                          ],
                                          1
                                        ),
                                  ],
                                  1
                                ),
                                _vm._l(_vm.serverAttrsData, function (item) {
                                  return _c(
                                    "a-descriptions-item",
                                    { key: item.key },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "attribute-label",
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [
                                          _vm.IsdeviceAttrEdit &&
                                          _vm.isRequired(item)
                                            ? _c(
                                                "span",
                                                {
                                                  staticStyle: { color: "red" },
                                                },
                                                [_vm._v("*")]
                                              )
                                            : _vm._e(),
                                          _vm._v(" " + _vm._s(item.name) + " "),
                                        ]
                                      ),
                                      _vm.IsdeviceAttrEdit
                                        ? _c(
                                            "div",
                                            [
                                              ["enum"].includes(
                                                item.definition.type
                                              )
                                                ? _c(
                                                    "a-select",
                                                    {
                                                      staticStyle: {
                                                        width: "100%",
                                                      },
                                                      attrs: {
                                                        placeholder:
                                                          "请选择" + item.name,
                                                        "allow-clear": true,
                                                      },
                                                      model: {
                                                        value: item.value,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "value",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "item.value",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.transformObjToOptions(
                                                        item.definition.specs
                                                      ),
                                                      function (subItem) {
                                                        return _c(
                                                          "a-select-option",
                                                          {
                                                            key: subItem.value,
                                                            attrs: {
                                                              value:
                                                                subItem.value,
                                                              title:
                                                                subItem.name,
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  subItem.name
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  )
                                                : ["bool"].includes(
                                                    item.definition.type
                                                  )
                                                ? _c(
                                                    "a-radio-group",
                                                    {
                                                      model: {
                                                        value: item.value,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "value",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "item.value",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.transformObjToOptions(
                                                        item.definition.specs
                                                      ),
                                                      function (subItem) {
                                                        return _c(
                                                          "a-radio",
                                                          {
                                                            key: subItem.name,
                                                            attrs: {
                                                              value:
                                                                subItem.value,
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                subItem.name
                                                              )
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  )
                                                : _c("a-input", {
                                                    staticStyle: {
                                                      width: "100%",
                                                    },
                                                    attrs: {
                                                      placeholder:
                                                        "请输入" + item.name,
                                                      title: item.value,
                                                    },
                                                    model: {
                                                      value: item.value,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "value",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "item.value",
                                                    },
                                                  }),
                                            ],
                                            1
                                          )
                                        : _c("div", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.getAttributeValue(item)
                                                ) +
                                                " "
                                            ),
                                          ]),
                                    ]
                                  )
                                }),
                              ],
                              2
                            )
                          : _vm._e(),
                        _c("div", { staticClass: "driver-title" }, [
                          _c("span", { staticClass: "descriptions-title" }, [
                            _vm._v("驱动信息"),
                          ]),
                          _vm.driverModel.type !== "DEVICE" ||
                          _vm.driveTableData.length
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "inline-block",
                                    "margin-left": "16px",
                                    "vertical-align": "middle",
                                  },
                                },
                                [
                                  !_vm.isMappingConfigEdit
                                    ? _c(
                                        "jtl-button",
                                        {
                                          staticClass:
                                            "description-title-button",
                                          attrs: { type: "primary" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.editMappingConfig(true)
                                            },
                                          },
                                        },
                                        [_vm._v("编辑")]
                                      )
                                    : _c(
                                        "span",
                                        [
                                          _c(
                                            "jtl-button",
                                            {
                                              staticClass:
                                                "description-title-button",
                                              attrs: { type: "primary" },
                                              on: { click: _vm.saveDriverInfo },
                                            },
                                            [_vm._v("保存")]
                                          ),
                                          _c(
                                            "jtl-button",
                                            {
                                              staticClass:
                                                "description-title-button",
                                              staticStyle: {
                                                "margin-left": "16px",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.editMappingConfig(
                                                    false
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("取消")]
                                          ),
                                        ],
                                        1
                                      ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]),
                        _c(
                          "div",
                          { staticClass: "driver-model" },
                          [
                            _c(
                              "a-descriptions",
                              {
                                staticClass: "tab-margin-16",
                                attrs: { bordered: "" },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "font-size": "15px",
                                      "font-weight": "normal",
                                      color: "#000000A6",
                                    },
                                    attrs: { slot: "title" },
                                    slot: "title",
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        "驱动连接配置" +
                                          _vm._s(
                                            _vm.driverModel.type !== "DEVICE"
                                              ? ""
                                              : " : " + _vm.fullDevicePath
                                          )
                                      ),
                                    ]),
                                  ]
                                ),
                                _vm._l(
                                  _vm.driverConnectionData,
                                  function (item) {
                                    return _c(
                                      "a-descriptions-item",
                                      { key: item.name },
                                      [
                                        _c(
                                          "template",
                                          { slot: "label" },
                                          [
                                            item.identifier === "AssistAlive"
                                              ? [
                                                  _vm.isMappingConfigEdit &&
                                                  _vm.driverModel.type !==
                                                    "DEVICE"
                                                    ? _c("a-checkbox", {
                                                        model: {
                                                          value: item.value,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "value",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.value",
                                                        },
                                                      })
                                                    : _vm._e(),
                                                  _c(
                                                    "span",
                                                    { staticClass: "m-l-12" },
                                                    [_vm._v("保活")]
                                                  ),
                                                ]
                                              : _c("span", [
                                                  _vm._v(_vm._s(item.name)),
                                                ]),
                                          ],
                                          2
                                        ),
                                        _vm.isMappingConfigEdit &&
                                        _vm.driverModel.type !== "DEVICE"
                                          ? _c(
                                              "div",
                                              { staticClass: "flex" },
                                              [
                                                item.identifier ===
                                                "AssistAlive"
                                                  ? [
                                                      item.value &&
                                                      item.value !== "false"
                                                        ? _vm._l(
                                                            _vm.assistAliveConfig,
                                                            function (config) {
                                                              return _c(
                                                                "div",
                                                                {
                                                                  key: config.identifier,
                                                                },
                                                                [
                                                                  [
                                                                    "enum",
                                                                  ].includes(
                                                                    config.type
                                                                  )
                                                                    ? _c(
                                                                        "a-select",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              width:
                                                                                "100%",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              placeholder:
                                                                                "请选择" +
                                                                                config.name,
                                                                              "allow-clear": true,
                                                                              disabled:
                                                                                !item.value,
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                config.value,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    config,
                                                                                    "value",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "config.value",
                                                                            },
                                                                        },
                                                                        _vm._l(
                                                                          _vm.transformObjToOptions(
                                                                            config.specs
                                                                          ),
                                                                          function (
                                                                            subItem
                                                                          ) {
                                                                            return _c(
                                                                              "a-select-option",
                                                                              {
                                                                                key: subItem.value,
                                                                                attrs:
                                                                                  {
                                                                                    value:
                                                                                      subItem.value,
                                                                                    title:
                                                                                      subItem.name,
                                                                                  },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  " " +
                                                                                    _vm._s(
                                                                                      subItem.name
                                                                                    ) +
                                                                                    " "
                                                                                ),
                                                                              ]
                                                                            )
                                                                          }
                                                                        ),
                                                                        1
                                                                      )
                                                                    : _c(
                                                                        "a-input-number",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              width:
                                                                                "100%",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              placeholder:
                                                                                "请输入" +
                                                                                config.name,
                                                                              disabled:
                                                                                !item.value,
                                                                              min:
                                                                                config
                                                                                  .specs
                                                                                  .min ||
                                                                                _vm.defaultMin,
                                                                              max:
                                                                                config
                                                                                  .specs
                                                                                  .max ||
                                                                                _vm.defaultMax,
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                config.value,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    config,
                                                                                    "value",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "config.value",
                                                                            },
                                                                        }
                                                                      ),
                                                                ],
                                                                1
                                                              )
                                                            }
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  : [
                                                      ["enum"].includes(
                                                        item.type
                                                      )
                                                        ? _c(
                                                            "a-select",
                                                            {
                                                              staticStyle: {
                                                                width: "100%",
                                                              },
                                                              attrs: {
                                                                placeholder:
                                                                  "请选择" +
                                                                  item.name,
                                                                "allow-clear": true,
                                                                disabled:
                                                                  _vm.isDisabled(
                                                                    item
                                                                  ),
                                                              },
                                                              model: {
                                                                value:
                                                                  item.value,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      item,
                                                                      "value",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "item.value",
                                                              },
                                                            },
                                                            _vm._l(
                                                              _vm.transformObjToOptions(
                                                                item.specs
                                                              ),
                                                              function (
                                                                subItem
                                                              ) {
                                                                return _c(
                                                                  "a-select-option",
                                                                  {
                                                                    key: subItem.value,
                                                                    attrs: {
                                                                      value:
                                                                        subItem.value,
                                                                      title:
                                                                        subItem.name,
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          subItem.name
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                            1
                                                          )
                                                        : ["bool"].includes(
                                                            item.type
                                                          )
                                                        ? _c(
                                                            "a-radio-group",
                                                            {
                                                              attrs: {
                                                                disabled:
                                                                  _vm.isDisabled(
                                                                    item
                                                                  ),
                                                              },
                                                              model: {
                                                                value:
                                                                  item.value,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      item,
                                                                      "value",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "item.value",
                                                              },
                                                            },
                                                            _vm._l(
                                                              _vm.transformBoolToOptions(
                                                                item.specs
                                                              ),
                                                              function (
                                                                subItem
                                                              ) {
                                                                return _c(
                                                                  "a-radio",
                                                                  {
                                                                    key: subItem.name,
                                                                    attrs: {
                                                                      value:
                                                                        subItem.value,
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        subItem.name
                                                                      )
                                                                    ),
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                            1
                                                          )
                                                        : _c("a-input", {
                                                            staticStyle: {
                                                              width: "100%",
                                                            },
                                                            attrs: {
                                                              placeholder:
                                                                "请输入" +
                                                                item.name,
                                                              title: item.value,
                                                              disabled:
                                                                _vm.isDisabled(
                                                                  item
                                                                ),
                                                            },
                                                            model: {
                                                              value: item.value,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    item,
                                                                    "value",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "item.value",
                                                            },
                                                          }),
                                                    ],
                                              ],
                                              2
                                            )
                                          : _c(
                                              "div",
                                              [
                                                item.identifier ===
                                                "AssistAlive"
                                                  ? [
                                                      item.value &&
                                                      item.value !== "false"
                                                        ? _vm._l(
                                                            _vm.assistAliveConfig,
                                                            function (config) {
                                                              return _c(
                                                                "span",
                                                                {
                                                                  key: config.identifier,
                                                                },
                                                                [
                                                                  config.type ===
                                                                  "enum"
                                                                    ? [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              _vm._.get(
                                                                                config,
                                                                                "specs[" +
                                                                                  config.value +
                                                                                  "]"
                                                                              ) ||
                                                                                config.value
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    : [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              config.value
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ],
                                                                ],
                                                                2
                                                              )
                                                            }
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  : item.type === "bool"
                                                  ? [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm._.get(
                                                              item,
                                                              "specs[" +
                                                                (item.value ===
                                                                  true ||
                                                                item.value ===
                                                                  "true"
                                                                  ? "1"
                                                                  : "0") +
                                                                "]"
                                                            ) || item.value
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  : item.type === "enum"
                                                  ? [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm._.get(
                                                              item,
                                                              "specs[" +
                                                                item.value +
                                                                "]"
                                                            ) || item.value
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  : [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(item.value) +
                                                          " "
                                                      ),
                                                    ],
                                              ],
                                              2
                                            ),
                                      ],
                                      2
                                    )
                                  }
                                ),
                              ],
                              2
                            ),
                            _vm.driveTableData.length
                              ? [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "ant-descriptions-title",
                                      staticStyle: {
                                        "margin-left": "16px",
                                        "font-weight": "normal",
                                        "font-size": "15px",
                                        color: "#000000A6",
                                      },
                                    },
                                    [_vm._v(" 映射配置 ")]
                                  ),
                                  _c("a-table", {
                                    staticStyle: { margin: "0 16px 16px 16px" },
                                    attrs: {
                                      "children-column-name": "noChildren",
                                      "data-source": _vm.driveTableData,
                                      pagination: false,
                                      bordered: true,
                                      scroll: _vm.tableScroll,
                                      columns: _vm.driveColumnsData,
                                      size: "small",
                                      "row-key": "index",
                                      "row-class-name": function (record) {
                                        return !record.existsRecord &&
                                          !_vm.isMappingConfigEdit
                                          ? "rowBackRed"
                                          : ""
                                      },
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        _vm._l(_vm.slotKeys, function (item) {
                                          return {
                                            key: item,
                                            fn: function (text, record) {
                                              return [
                                                _c(
                                                  "div",
                                                  { key: item },
                                                  [
                                                    _vm.isMappingConfigEdit
                                                      ? [
                                                          (_vm.isOnlyReader(
                                                            record
                                                          ) &&
                                                            _vm.isReadType(
                                                              item
                                                            )) ||
                                                          !_vm.hasObjectType(
                                                            record,
                                                            item
                                                          )
                                                            ? _c("span")
                                                            : ["enum"].includes(
                                                                _vm.formatVariables(
                                                                  record,
                                                                  item
                                                                ).type
                                                              )
                                                            ? _c(
                                                                "a-select",
                                                                {
                                                                  staticStyle: {
                                                                    width:
                                                                      "100%",
                                                                  },
                                                                  attrs: {
                                                                    placeholder:
                                                                      "请选择" +
                                                                      _vm.formatVariables(
                                                                        record,
                                                                        item
                                                                      ).name,
                                                                    disabled:
                                                                      _vm
                                                                        .formatObjectType(
                                                                          record.objectTypeProps,
                                                                          record.objectTypePropIdentifier
                                                                        )
                                                                        .includes(
                                                                          "只读"
                                                                        ) ||
                                                                      _vm.isDeviceDisabled(
                                                                        item
                                                                      ),
                                                                    "allow-clear": true,
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.formatVariables(
                                                                        record,
                                                                        item
                                                                      ).value,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm.formatVariables(
                                                                            record,
                                                                            item
                                                                          ),
                                                                          "value",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "formatVariables(record,item).value",
                                                                  },
                                                                },
                                                                _vm._l(
                                                                  _vm.formatVariables(
                                                                    record,
                                                                    item
                                                                  ).specs,
                                                                  function (
                                                                    itemm
                                                                  ) {
                                                                    return _c(
                                                                      "a-select-option",
                                                                      {
                                                                        key: itemm.value,
                                                                        attrs: {
                                                                          value:
                                                                            itemm.value,
                                                                          title:
                                                                            itemm.name,
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              itemm.name
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    )
                                                                  }
                                                                ),
                                                                1
                                                              )
                                                            : _c("a-input", {
                                                                staticStyle: {
                                                                  width: "100%",
                                                                },
                                                                attrs: {
                                                                  disabled:
                                                                    _vm.isDeviceDisabled(
                                                                      item
                                                                    ),
                                                                  placeholder:
                                                                    "请输入" +
                                                                    _vm.formatVariables(
                                                                      record,
                                                                      item
                                                                    ).name,
                                                                  title:
                                                                    _vm.formatVariables(
                                                                      record,
                                                                      item
                                                                    ).value,
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.formatVariables(
                                                                      record,
                                                                      item
                                                                    ).value,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.formatVariables(
                                                                          record,
                                                                          item
                                                                        ),
                                                                        "value",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "formatVariables(record,item).value",
                                                                },
                                                              }),
                                                          _vm.formatVariables(
                                                            record,
                                                            item
                                                          ).warningMsg
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticStyle: {
                                                                    color:
                                                                      "#faad14",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.formatVariables(
                                                                        record,
                                                                        item
                                                                      )
                                                                        .warningMsg
                                                                    )
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      : _c(
                                                          "span",
                                                          {
                                                            attrs: {
                                                              title:
                                                                _vm.formatShowText(
                                                                  item,
                                                                  record
                                                                ),
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.calculateByLength(
                                                                  _vm.formatShowText(
                                                                    item,
                                                                    record
                                                                  ),
                                                                  12
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                  ],
                                                  2
                                                ),
                                              ]
                                            },
                                          }
                                        }),
                                        {
                                          key: "name",
                                          fn: function (text) {
                                            return [
                                              _c(
                                                "span",
                                                {
                                                  class:
                                                    _vm.variablesNum < 2
                                                      ? "minName"
                                                      : _vm.variablesNum < 4
                                                      ? "mediaWidth"
                                                      : "maxName",
                                                  attrs: { title: text },
                                                },
                                                [_vm._v(_vm._s(text))]
                                              ),
                                            ]
                                          },
                                        },
                                        {
                                          key: "identifier",
                                          fn: function (text) {
                                            return [
                                              _c(
                                                "span",
                                                {
                                                  class:
                                                    _vm.variablesNum < 2
                                                      ? "minIdentifier"
                                                      : "maxIdentifier",
                                                  attrs: { title: text },
                                                },
                                                [_vm._v(_vm._s(text))]
                                              ),
                                            ]
                                          },
                                        },
                                        {
                                          key: "objectTypePropIdentifier",
                                          fn: function (text, record) {
                                            return _c(
                                              "div",
                                              {},
                                              [
                                                _vm.isMappingConfigEdit
                                                  ? _c(
                                                      "a-select",
                                                      {
                                                        staticStyle: {
                                                          width: "100%",
                                                        },
                                                        attrs: {
                                                          placeholder:
                                                            "请输入对象属性类型",
                                                        },
                                                        model: {
                                                          value:
                                                            record.objectTypePropIdentifier,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              record,
                                                              "objectTypePropIdentifier",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "record.objectTypePropIdentifier",
                                                        },
                                                      },
                                                      _vm._l(
                                                        record.objectTypeProps,
                                                        function (item, index) {
                                                          return _c(
                                                            "a-select-option",
                                                            {
                                                              key: index,
                                                              attrs: {
                                                                value:
                                                                  item.identifier,
                                                                title:
                                                                  item.name,
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    item.name
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    )
                                                  : _c(
                                                      "span",
                                                      {
                                                        attrs: {
                                                          title:
                                                            _vm.formatObjectType(
                                                              record.objectTypeProps,
                                                              record.objectTypePropIdentifier
                                                            ),
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.calculateByLength(
                                                                _vm.formatObjectType(
                                                                  record.objectTypeProps,
                                                                  record.objectTypePropIdentifier
                                                                ),
                                                                12
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                              ],
                                              1
                                            )
                                          },
                                        },
                                        {
                                          key: "pollInterval",
                                          fn: function (text, record) {
                                            return _c(
                                              "div",
                                              {},
                                              [
                                                _vm.isMappingConfigEdit
                                                  ? _c("a-input", {
                                                      staticStyle: {
                                                        width: "100%",
                                                      },
                                                      attrs: {
                                                        type: "number",
                                                        placeholder: "请输入",
                                                        min: 0,
                                                        suffix: "秒",
                                                      },
                                                      model: {
                                                        value:
                                                          record.commonConfig
                                                            .pollInterval,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            record.commonConfig,
                                                            "pollInterval",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "record.commonConfig.pollInterval",
                                                      },
                                                    })
                                                  : _c(
                                                      "span",
                                                      {
                                                        attrs: {
                                                          title: text
                                                            ? text + "秒"
                                                            : "",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.calculateByLength(
                                                              text
                                                                ? text + "秒"
                                                                : "",
                                                              8
                                                            )
                                                          ) + " "
                                                        ),
                                                      ]
                                                    ),
                                              ],
                                              1
                                            )
                                          },
                                        },
                                        {
                                          key: "coefficient",
                                          fn: function (text, record) {
                                            return _c(
                                              "div",
                                              {},
                                              [
                                                _vm.isMappingConfigEdit &&
                                                _vm.isNumberDataType(record)
                                                  ? _c("a-input-number", {
                                                      staticStyle: {
                                                        width: "100%",
                                                      },
                                                      attrs: {
                                                        placeholder:
                                                          "请输入系数",
                                                      },
                                                      model: {
                                                        value:
                                                          record.commonConfig
                                                            .coefficient,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            record.commonConfig,
                                                            "coefficient",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "record.commonConfig.coefficient",
                                                      },
                                                    })
                                                  : !_vm.isMappingConfigEdit
                                                  ? _c(
                                                      "span",
                                                      {
                                                        attrs: {
                                                          title:
                                                            _vm.coefficientFilter(
                                                              record
                                                            ),
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.calculateByLength(
                                                              _vm.coefficientFilter(
                                                                record
                                                              ),
                                                              8
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            )
                                          },
                                        },
                                        {
                                          key: "action",
                                          fn: function (text, record) {
                                            return _c("div", {}, [
                                              _c(
                                                "a",
                                                {
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.driverConfigClick(
                                                        record
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("配置")]
                                              ),
                                            ])
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ]
                              : _vm._e(),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm.IsEdge
                ? _c(
                    "a-tab-pane",
                    { key: "6", attrs: { tab: "设备影子" } },
                    [
                      _vm.deviceId
                        ? _c("device-shadow-component", {
                            attrs: { "device-id": _vm.deviceId },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("jtl-entity-dialog", {
        ref: "formDialog",
        on: { dialogOK: _vm.reload },
      }),
      _c("DriverConfigDialog", {
        ref: "driverConfigDialog",
        attrs: { "things-model": _vm.deviceAttrs },
        on: {
          dialogOK: function ($event) {
            return _vm.refreshConfigData($event)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }