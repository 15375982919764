





















































import { Component, Mixins } from 'vue-property-decorator';
import TableDialogFormComponent from '@common-src/mixins/table-dialog-form-component';
import DrawerComponent from '@common-src/mixins/drawer-component';
import { DeviceEventEntityModel } from '@common-src/entity-model/device-event-entity';
import PRESENTED_IMAGE_SIMPLE from 'ant-design-vue/lib/empty/simple';

@Component
export default class DeviceEventDetailDrawer extends Mixins(TableDialogFormComponent, DrawerComponent) {
    deviceModel: DeviceEventEntityModel = new DeviceEventEntityModel();
    eventList: any = undefined;
    picUrl:string = '';
    fullScreenVisible:boolean = false;
    tableColumns:any = [
        {
            dataIndex: 'name',
            key: 'name',
            title: '参数名称'
        }, {
            dataIndex: 'identifier',
            key: 'identifier',
            title: '参数标识符'
        }, {
            dataIndex: 'value',
            key: 'value',
            title: '值'
        }, {
            dataIndex: 'dataDefinition',
            key: 'dataDefinition',
            title: '数据定义'
        }, {
            dataIndex: 'unit',
            key: 'unit',
            title: '单位'
        }
    ];
    /**
     * 获取空项目状态图标
     */
    get simpleImage() {
        return PRESENTED_IMAGE_SIMPLE;
    }
    get FilterDataList(): Array<any> {
        const obj = this.deviceModel.outputData;
        const arr = [];
        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
                const eventOutputData = this.eventList.find(item => item.identifier === this.deviceModel.identifier).outputData;
                if (!_.isEmpty(eventOutputData)) {
                    const eventData = eventOutputData.find(item => item.identifier === key);
                    const hasEnumList = eventData?.thingsModelTypeModel.enumList.find(item => item.value === obj[key]) || false;
                    arr.push({
                        identifier: key,
                        value: obj[key],
                        name: eventData.name,
                        unit: eventData.thingsModelTypeModel.unit || '',
                        dataDefinition: hasEnumList ? hasEnumList.label : ''
                    });
                }
            }
        }
        return arr;
    }

    drawerShow(model: DeviceEventEntityModel, eventList:any) {
        this.deviceModel = model;
        this.eventList = eventList.events;
        this.drawerOpen(model.id);
    }
    showBig(val) {
        this.picUrl = val;
        this.fullScreenVisible = true;
    }
}
