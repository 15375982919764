






















import { Component, Prop, Mixins } from 'vue-property-decorator';
import FormDialogComponent from '@common-src/mixins/form-dialog-component';
import DeviceAttributeService from '@common-src/service/device-attribute';
import { ThingsModelType, ViewModeType } from '@common-src/model/enum';
import { ThingsModelTypeModel } from '@common-src/entity-model/things-model-type-entity';
import { ThingsAttributeEntityModel } from '@common-src/entity-model/things-attribute-entity';
import { FormControlType } from '@common-src/model/form-control';
import FormComponent from '@common-src/mixins/form-component';
import RpcComponent from '@common-src/mixins/rpc-component';
import { ModuleType } from '@common-src/enum/module-type.enum';

@Component
export default class DeviceWriteAttributeComponent extends Mixins(FormDialogComponent, RpcComponent) {
    attributeModel: any;
    deviceId: string;
    isOnline: boolean;
    rpcModel: any = {};

    get IsEdge() {
        return JTL.moduleType === ModuleType.EDGE;
    }

    writeAttributedialogOpen(model: any, deviceId: string, isOnline: boolean) {
        this.isOnline = isOnline;
        this.deviceId = deviceId;
        this.attributeModel = model;
        this.dialogVisible = true;
        this.$nextTick(() => {
            this.initAttributeForm(model);
        });
    }

    initAttributeForm(model: any) {
        const thingsModelTypeModel: ThingsModelTypeModel = new ThingsModelTypeModel().toModel(model.definition);
        const paramType = thingsModelTypeModel.thingsModelType;
        const controlItem: any = {
            key: model.identifier,
            value: model.value,
            valueDesc: model.valueDesc,
            label: model.name,
            type: null,
            layout: { labelCol: 6, wrapperCol: 15, width: '100%' },
            required: true
        };
        switch (paramType) {
            case ThingsModelType.INT:
            case ThingsModelType.DOUBLE:
            case ThingsModelType.FLOAT:
                controlItem.type = FormControlType.NUMBER;
                controlItem.inputType = 'number';
                controlItem.min = thingsModelTypeModel.dataMin;
                controlItem.max = thingsModelTypeModel.dataMax;
                controlItem.step = thingsModelTypeModel.step;
                controlItem.message = thingsModelTypeModel.unit || '';
                controlItem.noMessageTooltip = true;
                controlItem.placeholder = `请输入${controlItem.label}`;
                // 百分比规范调整
                if (thingsModelTypeModel.unit && thingsModelTypeModel.unit === '%') {
                    controlItem.value = parseFloat((_.toNumber(model.value) * 100).toPrecision(12));
                }
                break;
            case ThingsModelType.BOOLEAN:
                controlItem.type = FormControlType.SWITCH;
                controlItem.checkText = thingsModelTypeModel.dataTrueName;
                controlItem.uncheckText = thingsModelTypeModel.dataFalseName;
                // controlItem.value = !!model.definition.val;
                controlItem.required = false;
                break;
            case ThingsModelType.ENUM:
            {
                controlItem.type = FormControlType.SELECT;
                controlItem.options = thingsModelTypeModel.enumList;
                controlItem.placeholder = `请选择${controlItem.label}`;
                controlItem.value = !_.isEmpty(_.get(model, 'value')) ? _.get(model, 'value').toString() : _.get(model, 'value');
                break;
            }
            case ThingsModelType.JSON:
                controlItem.type = FormControlType.TEXT_AREA;
                controlItem.placeholder = `请输入${controlItem.label}`;
                controlItem.value = JSON.stringify(controlItem.value);
                break;
            case ThingsModelType.STRING:
            case ThingsModelType.TEXT:
            default:
                controlItem.type = FormControlType.TEXT;
                break;
        }
        (this.$refs.attributeForm as FormComponent<any>).initFormByControls([controlItem], ViewModeType.UPDATE);
    }

    writeAttributeValue() {
        return new Promise((resolve, reject) => {
            (this.$refs.attributeForm as FormComponent<any>).submitForm().then(res => {
                this.rpcModel.rpcResult = null;
                const params = [{
                    identifier: this.attributeModel.identifier,
                    scope: this.attributeModel.scope,
                    value: (this.$refs.attributeForm as FormComponent<any>).formModel[this.attributeModel.identifier]
                }];
                if (this.attributeModel.unit && this.attributeModel.unit === '%') {
                    params[0].value = parseFloat((_.toNumber(params[0].value) / 100).toPrecision(12));
                }
                DeviceAttributeService.writeAttribute(this.deviceId, params).then(res => {
                    if (res) {
                        this.initGetRequestResultInterval(this.rpcModel, res, this.IsEdge);
                        if (this.IsEdge) {
                            if (!this.isOnline) {
                                const confirm = this.$info({
                                    title: '提示',
                                    content: '当前设备离线，修改指令已缓存设备影子',
                                    cancelButtonProps: { style: { display: 'none' } }
                                });
                            }
                            this.showMessageSuccess('执行成功');
                        }
                        this.$emit('dialogOk');
                    } else {
                        this.showMessageSuccess('执行失败');
                    }
                    resolve(null);
                }).catch(() => {
                    reject();
                });
            }).catch(() => {
                reject();
            });
        });
    }

    onDialogClose() {
        this.$emit('rpcInterval', this.rpcModel);
        this.dialogClose();
        this.clearTimer(this.rpcModel);
    }

    destroyed() {
        this.clearTimer(this.rpcModel);
    }
}
